<template>
  <div
    v-if="active"
    class="movable-popup shadow-2xl rounded-lg"
    :ref="refVal"
    :class="transition === 'transition_out' ? 'slideXLeftOut' : ''"
    :style="`z-index: ${zIndex}; width: ${width}; backdrop-filter: blur(${blur}px); margin-left: ${marginLeft}px`"
  >
    <movable class="movable-top border card-border flex justify-between" :target="refVal" :style="`background: ${topBg}`">
      <p class="mr-2 truncate">{{ title }}</p>
    </movable>
    <div style="margin-top: 25px" :style="`background: ${background}`" class="rounded-b-lg" :class="`p-${padding}`">
      <slot name="content"></slot>
    </div>
    <div class="movableCloseBtn" v-if="closeButton">
      <feather-icon @click="close" icon="XCircleIcon" svgClasses="w-6 h-6 text-white"></feather-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MovablePopup',
  data() {
    return {
      transition: false,
    };
  },
  props: {
    active: {
      default: false,
      required: true,
    },
    refVal: {
      required: true,
    },
    closeButton: {
      default: true,
      required: false,
    },
    title: {
      default: '',
      required: false,
    },
    topBg: {
      default: null,
      required: false,
    },
    mainBg: {
      default: null,
      required: false,
    },
    blur: {
      default: 0,
      required: false,
    },
    zIndex: {
      default: '999999',
      required: false,
    },
    width: {
      default: '40vw',
      required: false,
    },
    padding: {
      default: '5',
      required: false,
    },
    marginLeft: {
      default: 0,
      required: false,
    },
  },
  computed: {
    background() {
      if (this.mainBg) return this.mainBg;
      const theme = this.$store.state.theme;
      if (theme === 'light') return 'rgb(224, 224, 224)';
      if (theme === 'dusk') return 'rgb(9 5 64)';
      if (theme === 'dark') return 'rgb(4 9 22)';
    },
  },
  methods: {
    close() {
      this.transition = 'transition_out';
      setTimeout(() => {
        this.transition = false;
        this.$emit('close');
      }, 400);
    },
  },
};
</script>

<style lang="scss" scoped>
.movable-top {
  background: #0053ff;
  display: block;
  width: 100%;
  color: white;
  padding: 4px 7px 4px 7px;
  z-index: 9;
  // max-height: 28px;
  border-radius: 0.55rem 0.55rem 0 0;
  cursor: grab !important;
}

.movable-top:active {
  cursor: grabbing;
}

.movableCloseBtn {
  top: 0;
  right: 0;
  position: absolute;
  padding: 3px 4px;
  z-index: 99;
  cursor: pointer !important;
}

.movable-popup {
  display: block;
  position: absolute;
  color: white;
  top: 0;
  margin-top: 130px;
  max-width: 900px;
  min-width: 300px;
  width: 30vw;
  animation-duration: 300ms;
  animation-delay: 0ms;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.25, 0.8, 0.5, 1);
  animation-name: slideXLeftIn;
  border: 1px solid #181d2a;
}

@keyframes slideXLeftIn {
  from {
    opacity: 0;
    transform: translateX(-15px);
  }

  to {
    opacity: 1;
  }
}

.slideXLeftIn {
  animation-name: slideXLeftIn;
}

@keyframes slideXLeftOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translateX(-15px);
  }
}

.slideXLeftOut {
  animation-name: slideXLeftOut;
}
</style>
