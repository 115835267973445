<template>
  <div @contextmenu.prevent @click="antiScreenshotDisableClick">
    <div id="AntiPrint" class="hidden">
      <img src="https://connect-cdn.intellectualpoint.com/assets/images/misc/copyright-infringement.jpg" width="500" class="mb-5" />
      <h1 class="text-white">Printing, Recording, Screenshotting or Sharing Simulations Is Not Allowed<br /><br /></h1>
      <h1 class="text-white">{{ activeUserInfo.id }}</h1>
      <h1 class="text-white">{{ activeUserInfo.firstName }} {{ activeUserInfo.lastName }}</h1>
      <h1 class="text-white">{{ activeUserInfo.email }}</h1>
    </div>
    <div v-if="simulationData" ref="cursor" class="vx-row simulation-page" id="LegacySimulationRenderer">
      <div class="vx-col lg:w-9/12 md:w-8/12 sm:w-5/5">
        <div class="vx-row">
          <div class="vx-col w-full">
            <vx-card class="mb-5">
              <div class="flex justify-between items-center align-middle">
                <div class="flex items-center align-middle">
                  <img
                    :src="simulationData.thumbnail"
                    v-if="simulationData.thumbnail"
                    width="40"
                    height="40"
                    class="rounded shadow-md cursor-pointer block mr-3"
                    draggable="false"
                    alt=""
                  />
                  <div class="flex flex-row items-center gap-x-2">
                    <span>
                      <vs-input v-if="enableEditMode" v-model="simulationData.name" class="ml-1" style="min-width: 14vw"></vs-input>
                      <h2 v-else class="font-bold ml-2">{{ simulationData.name }}</h2>
                    </span>
                    <span class="font-bold ml-2" v-if="simulationType === 'exercises'">|</span>
                    <h2 class="font-light ml-2" v-if="simulationType === 'exercises'">
                      {{ exerciseNames[[simulationExerciseConfirmed - 1]] }}
                    </h2>
                  </div>
                </div>
                <div class="flex items-center align-middle">
                  <vs-button color="danger" icon-pack="feather" icon="icon-arrow-left" @click="$router.push('/simulations/select')"
                    >Exit To Simulation Selector
                  </vs-button>
                </div>
              </div>
            </vx-card>
          </div>
        </div>
        <component
          :class="(!simulationPaused && simulationAntiSkid) || !simulationAntiSkid ? 'block' : 'hidden'"
          :is="dynamicComponent"
          :key="renderKey"
          ref="simulation"
          :data="{ simulation: simulationData, options: simulationOptions }"
          v-on:enableMarkButton="enableMarkButton"
          v-on:simulation_full_correct="simulationFullCorrect"
          v-on:simulation_exercise_correct="simulationExerciseCorrect"
          v-on:simulation_quiz_correct="simulationQuizCorrect"
          v-on:simulation_not_complete="simulationNotComplete"
          v-on:simulation_show_popup="simulationShowPopupFunction"
          v-on:simulation_complete_not_quiz="simulationCompleteNotQuiz"
          v-on:simulation_getting_there="simulationGettingThere"
          v-on:simulation_update_score="simulationUpdateScore"
          v-on:simulation_update_options="simulationUpdateOptions"
        />
        <div :class="(!simulationPaused && simulationAntiSkid) || !simulationAntiSkid ? 'hidden' : 'block'" class="paused-overlay">
          <div class="flex items-center justify-center">
            <img src="https://connect-cdn.intellectualpoint.com/assets/images/logos/IntellectualPoint.png" width="400" alt="IP Logo" />
          </div>
          <h1 class="mt-10 font-bold text-center">You Are Currently Paused</h1>
          <h1 class="mt-4 font-light text-center">To Resume Tap In The Middle Of The Screen</h1>
        </div>
      </div>
      <div class="vx-col lg:w-3/12 md:w-4/12 sm:w-5/5">
        <vx-card class="mb-4">
          <div class="flex justify-between items-center">
            <div>
              <h2 class="mb-1">Simulation Progress</h2>
              <template>
                <h5 class="font-bold mb-1" v-if="this.simulationType === 'exercises'">
                  Exercise:
                  <span class="font-light">{{ updatedcurrentExercise }} / {{ exerciseSimulationCurrentScore.totalExercises }}</span>
                </h5>
              </template>
              <h5 class="font-bold mb-1" v-if="this.simulationType !== 'exercises'">
                Score: <span class="font-light">{{ simulationCurrentScore.correct }} / {{ simulationData.totalScore }}</span>
              </h5>
              <h5 class="font-bold mb-1">Difficulty: <span class="font-light">Normal</span></h5>
              <h5 class="font-bold mb-1">
                Previously Completed: <span class="font-light">{{ simulationBestAttemptData.completed ? 'Yes' : 'No' }}</span>
              </h5>
            </div>
            <vs-input-number v-if="enableEditMode && simulationType !== 'exercises'" min="0" v-model="simulationData.totalScore" />
            <radial-progress-bar
              v-if="this.simulationType === 'exercises'"
              :diameter="115"
              :completed-steps="exerciseSimulationCurrentScore.currentExercise"
              :total-steps="updatedTotalExercises"
              :animateSpeed="1100"
              timingFunc="ease"
              innerStrokeColor="#181d2a"
              startColor="#31B952"
              stopColor="#0cdc62"
              :strokeWidth="7"
              :innerStrokeWidth="7"
            >
              <h2 class="font-light">{{ exerciseSimulationCurrentScore.percent }}%</h2>
            </radial-progress-bar>

            <radial-progress-bar
              v-if="this.simulationType !== 'exercises'"
              :diameter="115"
              :completed-steps="simulationCurrentScore.correct"
              :total-steps="simulationData.totalScore"
              :animateSpeed="1100"
              timingFunc="ease"
              innerStrokeColor="#181d2a"
              startColor="#31B952"
              stopColor="#0cdc62"
              :strokeWidth="7"
              :innerStrokeWidth="7"
            >
              <h2 class="font-light">{{ simulationCurrentScore.percent }}%</h2>
            </radial-progress-bar>
          </div>
        </vx-card>
        <vx-card class="mb-4" collapse-action title="Instructions">
          <div>
            <!-- simulation instructions -->
            <quill-editor v-if="enableEditMode && simulationType !== 'exercises'" v-model="simulationData.instructions"></quill-editor>
            <quill-editor
              v-if="enableEditMode && simulationType === 'exercises' && simulationExerciseConfirmed === 1"
              v-model="simulationData.instructions"
            ></quill-editor>
            <quill-editor
              v-if="enableEditMode && simulationType === 'exercises' && simulationExerciseConfirmed !== 1"
              v-model="exerciseInstructionContent[simulationExerciseConfirmed - 2]"
            ></quill-editor>
            <div
              v-if="simulationType !== 'exercises' && !enableEditMode"
              class="mb-6 instructions-show-render"
              :class="(!simulationPaused && simulationAntiSkid) || !simulationAntiSkid ? 'block' : 'instructions-blur'"
              v-html="simulationData.instructions"
            />
            <div
              v-if="simulationType === 'exercises' && !enableEditMode && simulationExerciseConfirmed === 1"
              class="mb-6 instructions-show-render"
              :class="(!simulationPaused && simulationAntiSkid) || !simulationAntiSkid ? 'block' : 'instructions-blur'"
              v-html="simulationData.instructions"
            />
            <div
              v-if="simulationType === 'exercises' && !enableEditMode && simulationExerciseConfirmed !== 1"
              class="mb-6 instructions-show-render"
              :class="(!simulationPaused && simulationAntiSkid) || !simulationAntiSkid ? 'block' : 'instructions-blur'"
              v-html="exerciseInstructionContent[simulationExerciseConfirmed - 2]"
            />

            <vs-input
              v-if="enableEditMode"
              v-model="simulationData.explanation_video_id"
              class="w-full mt-10 mb-5"
              label-placeholder="Explanation Video Link"
            ></vs-input>
            <vs-button
              v-if="!enableEditMode && simulationData.explanation_video_id"
              class="mt-3 font-bold w-full"
              color="primary"
              icon="icon-film"
              icon-pack="feather"
              type="border"
              @click="toggleExplanationVideo"
            >
              {{ showExplanationVideo === false ? 'Watch the Simulation Video' : 'Hide the Simulation Video' }}
            </vs-button>
          </div>
        </vx-card>
        <vx-card class="mb-4 pt-0">
          <div v-if="!enableEditMode">
            <vs-button
              v-if="!simulationComplete"
              class="my-2 font-bold flex"
              color="warning"
              icon="icon-refresh-cw"
              icon-pack="feather"
              size="large"
              style="width: 100%; z-index: 1"
              type="filled"
              @click="resetSimulation"
              >Reset Simulation
            </vs-button>
            <vs-button
              v-if="
                simulationCurrentAttemptData.completed &&
                simulationType === 'exercises' &&
                simulationExerciseConfirmed !== totalSimulationExercises
              "
              id="skipBtn"
              class="my-2 font-bold flex"
              color="primary"
              icon="icon-skip-forward"
              icon-pack="feather"
              size="large"
              style="width: 100%; z-index: 1"
              type="filled"
              @click="skipExercise"
              >Skip Exercise
            </vs-button>
            <vs-button
              v-if="!simulationComplete && simulationType !== 'exercises' && simulationOptions.showMarkButton"
              id="markBtn"
              class="my-2 font-bold flex"
              color="success"
              icon="icon-check-circle"
              icon-pack="feather"
              size="large"
              style="width: 100%; z-index: 1"
              type="filled"
              @click="markSimulation"
              >Submit Simulation
            </vs-button>
            <vs-button
              v-if="!simulationComplete && simulationType === 'exercises' && simulationExercise === totalSimulationExercises"
              id="markBtn"
              class="my-2 font-bold flex"
              color="success"
              icon="icon-check-circle"
              icon-pack="feather"
              size="large"
              style="width: 100%; z-index: 1"
              type="filled"
              @click="markSimulation"
              >Submit Simulation
            </vs-button>
            <vs-button
              v-if="
                !simulationComplete &&
                simulationType === 'exercises' &&
                simulationOptions.showMarkButton &&
                simulationExercise !== totalSimulationExercises
              "
              id="markBtn"
              class="my-2 font-bold flex"
              color="success"
              icon="icon-check-circle"
              icon-pack="feather"
              size="large"
              style="width: 100%; z-index: 1"
              type="filled"
              @click="markSimulation"
              >Next Exercise
            </vs-button>
            <div v-if="!simulationComplete && !simulationOptions.showMarkButton" class="flex align-middle mt-5">
              <vx-tooltip text="The Simulation Will Be Automatically Submitted Once All Steps Are Completed">
                <feather-icon icon="InfoIcon" svgClasses="h-6 w-6 text-white" class="mr-1" />
              </vx-tooltip>
              <p class="ml-1 self-center+">Auto Submission Is On</p>
            </div>
            <vs-button
              v-if="simulationComplete"
              class="my-2 font-bold flex"
              color="warning"
              icon="icon-refresh-cw"
              icon-pack="feather"
              size="large"
              style="width: 100%; z-index: 1"
              type="filled"
              @click="confirmResetSimulation"
              >Play Again
            </vs-button>
            <vs-button
              v-if="simulationComplete"
              class="my-2 font-bold flex"
              color="success"
              icon="icon-arrow-right-circle"
              icon-pack="feather"
              size="large"
              style="width: 100%; z-index: 1"
              type="filled"
              @click="confirmExitSimulation"
              >Next Simulation
            </vs-button>
          </div>
          <vs-button
            v-else
            class="w-full font-bold text-lg mb-6"
            icon="icon-edit"
            icon-pack="feather"
            type="filled"
            color="success"
            @click="updateFullSimulation"
            >Update Simulation
          </vs-button>
          <div v-if="$acl.check('proctor')" class="flex items-center justify-start align-middle mt-6">
            <p class="mr-2">Admin Mode</p>
            <vs-switch class="mr-2" v-model="enableEditMode" color="success"></vs-switch>
            <p class="ml-2 mr-2">Anti-Skid</p>
            <vs-switch v-model="simulationAntiSkid" color="success"></vs-switch>
          </div>
        </vx-card>
      </div>

      <movable-popup
        ref="videoPlayer"
        refVal="videoPlayerRef"
        title="Playing Video - Click Here to Drag Video Player"
        :active="showExplanationVideo"
        v-on:close="showExplanationVideo = false"
        padding="0"
      >
        <template v-slot:content>
          <div style="position: relative; padding-top: 56.25%">
            <iframe
              :src="simulationData.explanation_video_id"
              style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%"
              allow="accelerometer; gyroscope;  autoplay; encrypted-media; picture-in-picture;"
              allowfullscreen="true"
            ></iframe>
            <!-- <iframe
              :src="'https://player.osidex.stream/vod/' + simulationData.explanation_video_id"
              style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%"
              allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
              allowfullscreen="true"
            ></iframe> -->
          </div>
          <!-- <hls-player
            player_class="rounded-b"
            source="https://watch.videodelivery.net/14d2f6dc7a724f4317a8da5fc8fa9bc6"></hls-player> -->
        </template>
      </movable-popup>

      <!--- SIMULATION SUBMIT POPUP MESSAGE --->
      <vs-popup :active.sync="simulationSubmitPopup.active" class="pb-3" :title="simulationSubmitPopup.title">
        <div v-if="simulationSubmitPopup.type === 'warning'">
          <div class="flex justify-center mt-3 mb-5">
            <img src="https://connect-cdn.intellectualpoint.com/assets/images/icons/simulation-popup/danger.png" width="150" alt="Check" />
          </div>
          <h1 class="text-center font-bold my-6" style="font-size: 42px">
            {{ simulationSubmitPopup.score }} / {{ simulationData.totalScore }}
          </h1>
          <h3 class="text-center font-light mb-3">You're Getting There! Keep Practicing To Get 100%</h3>
          <div class="flex justify-center mt-3">
            <vs-button class="mt-5 mb-5" color="warning" @click="simulationSubmitPopup.active = false">Try Again</vs-button>
          </div>
        </div>
        <div v-if="simulationSubmitPopup.type === 'complete'">
          <div class="flex justify-center mt-3 mb-5">
            <img src="https://connect-cdn.intellectualpoint.com/assets/images/icons/simulation-popup/success.png" width="150" alt="Check" />
          </div>
          <h1 class="text-center font-bold my-6" style="font-size: 42px">
            {{ simulationSubmitPopup.score }} / {{ simulationData.totalScore }}
          </h1>
          <h3 class="text-center font-light mb-3">Amazing Job! Simulation Complete</h3>
          <div class="flex justify-center mt-3">
            <vs-button class="mt-5 mb-5" color="success" @click="simulationSubmitPopup.active = false">Continue</vs-button>
          </div>
        </div>
      </vs-popup>

      <!--- SIMULATION EXERCISE SUBMIT POPUP MESSAGE --->
      <vs-popup :active.sync="simulationExerciseSubmitPopup.active" class="pb-3" :title="simulationExerciseSubmitPopup.title">
        <div v-if="simulationExerciseSubmitPopup.type === 'warning'">
          <div class="flex justify-center mt-3 mb-5">
            <img src="https://connect-cdn.intellectualpoint.com/assets/images/icons/simulation-popup/danger.png" width="150" alt="Check" />
          </div>
          <h1 class="text-center font-bold my-6" style="font-size: 42px">
            {{ simulationExerciseSubmitPopup.score }} / {{ updatedPopupTotalScoreAmount }}
          </h1>
          <h3 class="text-center font-light mb-3">You're Getting There! Keep Practicing To Get 100%</h3>
          <div class="flex justify-center mt-3">
            <vs-button class="mt-5 mb-5" color="warning" @click="simulationExerciseSubmitPopup.active = false">Try Again</vs-button>
          </div>
        </div>
        <div v-if="simulationExerciseSubmitPopup.type === 'complete'">
          <div class="flex justify-center mt-3 mb-5">
            <img src="https://connect-cdn.intellectualpoint.com/assets/images/icons/simulation-popup/success.png" width="150" alt="Check" />
          </div>
          <h1 class="text-center font-bold my-6" style="font-size: 42px">
            {{ simulationExerciseSubmitPopup.score }} / {{ updatedPopupTotalScoreAmount }}
          </h1>
          <h3 class="text-center font-light mb-3">You successfully completed the exercise!</h3>
          <div class="flex justify-center mt-3">
            <vs-button class="mt-5 mb-5" color="success" @click="nextExerciseClick">Next Exercise</vs-button>
          </div>
        </div>
      </vs-popup>

      <!--- SIMULATION EXERCISE SUBMIT POPUP MESSAGE --->
      <vs-popup :active.sync="simulationQuizSubmitPopup.active" class="pb-3" :title="simulationQuizSubmitPopup.title">
        <div v-if="simulationQuizSubmitPopup.type === 'complete'">
          <div class="flex justify-center mt-3 mb-5">
            <img src="https://connect-cdn.intellectualpoint.com/assets/images/icons/simulation-popup/success.png" width="150" alt="Check" />
          </div>
          <h1 class="text-center font-bold my-6" style="font-size: 42px">{{ simulationQuizSubmitPopup.questions }} Questions Completed</h1>
          <h3 class="text-center font-light mb-3">You successfully completed the quiz!</h3>
          <div class="flex justify-center mt-3">
            <vs-button class="mt-5 mb-5" color="success" @click="simQuizCompletePopupClose">Close</vs-button>
          </div>
        </div>
      </vs-popup>

      <!--- SIMULATION EXERCISE SUBMIT POPUP MESSAGE --->
      <vs-popup :active.sync="simulationCompleteNotQuizPopup.active" class="pb-3" :title="simulationCompleteNotQuizPopup.title">
        <div v-if="simulationCompleteNotQuizPopup.type === 'complete'">
          <div class="flex justify-center mt-3 mb-5">
            <img src="https://connect-cdn.intellectualpoint.com/assets/images/icons/simulation-popup/danger.png" width="150" alt="Check" />
          </div>
          <h1 class="text-center font-bold my-6" style="font-size: 42px">Quiz Incomplete</h1>
          <h3 class="text-center font-light mb-3">You answered the simulation correctly but did not complete the quiz!</h3>
          <div class="flex justify-center mt-3">
            <vs-button class="mt-5 mb-5" color="warning" @click="simAlmostCompletePopupClose">Close</vs-button>
          </div>
        </div>
      </vs-popup>

      <vs-popup :active.sync="simulationNotCompletePopup.active" class="pb-3" :title="simulationNotCompletePopup.title">
        <div v-if="simulationNotCompletePopup.type === 'warning'">
          <div class="flex justify-center mt-3 mb-5">
            <img src="https://connect-cdn.intellectualpoint.com/assets/images/icons/simulation-popup/danger.png" width="150" alt="Check" />
          </div>
          <h1 class="text-center font-bold my-6" style="font-size: 42px">Incomplete Simulation</h1>
          <h3 class="text-center font-light mb-3">{{ simulationNotCompletePopup.message }}</h3>
          <div class="flex justify-center mt-3">
            <vs-button class="mt-5 mb-5" color="warning" @click="simNotCompletePopupClose">Close</vs-button>
          </div>
        </div>
      </vs-popup>

      <vs-popup :active.sync="simulationShowPopup.active" class="pb-3" :title="simulationShowPopup.title">
        <div v-if="simulationShowPopup.type === 'warning'">
          <div class="flex justify-center mt-3 mb-5">
            <img src="https://connect-cdn.intellectualpoint.com/assets/images/icons/simulation-popup/danger.png" width="150" alt="Check" />
          </div>
          <h1 class="text-center font-bold my-6" style="font-size: 42px">{{ simulationShowPopup.header }}</h1>
          <h3 class="text-center font-light mb-3">{{ simulationShowPopup.message }}</h3>
          <div class="flex justify-center mt-3">
            <vs-button class="mt-5 mb-5" color="warning" @click="simShowPopupClose">Close</vs-button>
          </div>
        </div>
      </vs-popup>

      <!--- USER DISPLAY INFO --->
      <div class="vx-col">
        <p class="mt-4" style="opacity: 85%">{{ activeUserInfo.firstName }} {{ activeUserInfo.lastName }} - {{ activeUserInfo.email }}</p>
      </div>
    </div>
    <div class="simple-spinner" v-else>
      <span></span>
    </div>
  </div>
</template>
<script>
import 'quill/dist/quill.snow.css';
import { quillEditor } from 'vue-quill-editor';
import MovablePopup from '../../../../../components/popup/MovablePopup';
import RadialProgressBar from 'vue-radial-progress';
import LottieAnimation from '../../../../../components/lottie/LottieAnimation';
import { useStopwatch } from 'vue-timer-hook';

export default {
  data() {
    return {
      componentFile: null,
      simulationData: null,
      simulationCurrentAttemptData: null,
      simulationBestAttemptData: null,
      simulationProgressSummary: {
        score: 0,
        percent: 0,
      },

      showGettingThereDialog: false,
      showSimulationFullCorrect: false,
      simulationComplete: false,
      disableMarkButton: false,

      simulationType: null,
      simulationExercise: 1,
      simulationExerciseConfirmed: 1,
      totalSimulationExercises: null,
      totalSimulationExerciseScores: [],
      exerciseInstructionContent: [],
      exerciseNames: [],
      updatedPopupTotalScoreAmount: 0,

      simulationCurrentScore: {
        id: 1,
        name: 'Simulation Progress',
        color: 'success',
        percent: 0,
        total: 0,
        correct: 0,
      },
      exerciseSimulationCurrentScore: {
        id: 1,
        name: 'Simulation Progress',
        color: 'success',
        percent: 0,
        totalExercises: 0,
        currentExercise: 0,
      },
      simulationSubmitPopup: {
        active: false,
        score: 0,
        title: 'Simulation Results',
        type: null,
      },
      simulationExerciseSubmitPopup: {
        active: false,
        score: 0,
        title: 'Simulation Results',
        type: null,
      },
      simulationQuizSubmitPopup: {
        active: false,
        questions: 0,
        title: 'Quiz Completed!',
        type: null,
      },
      simulationCompleteNotQuizPopup: {
        active: false,
        title: 'Simulation Complete But Not Quiz!',
        type: null,
      },
      simulationNotCompletePopup: {
        active: false,
        title: 'Simulation Not Complete!',
        message: null,
        type: null,
      },
      simulationShowPopup: {
        active: false,
        title: 'Simulation Message',
        message: null,
        header: null,
        type: null,
      },
      simulationOptions: {
        showMarkButton: true,
      },

      renderKey: 0,
      currentAttempt: 0,

      isUserProctor: false,
      enableEditMode: false,
      editDescriptionCounter: 0,

      simulationPaused: false,
      simulationAntiSkid: true,
      keyDown: false,

      showExplanationVideo: false,

      loadedComponent: null,

      stopwatch: {
        timeActive: null,
        timeTotal: null,
      },
    };
  },
  computed: {
    dynamicComponent() {
      // eslint-disable-next-line
      return () => import(`./templates/${this.simulationData.filePath}.vue`);
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    activeCourseInfo() {
      return this.$store.state.AppActiveCourse;
    },
    updatedcurrentExercise() {
      if (this.exerciseSimulationCurrentScore.currentExercise === 0) {
        return 1; // Increment to 1
      } else if (this.exerciseSimulationCurrentScore.currentExercise === this.updatedTotalExercises) {
        return this.exerciseSimulationCurrentScore.currentExercise - 1;
      } else {
        return this.exerciseSimulationCurrentScore.currentExercise;
      }
    },
    updatedTotalExercises() {
      return this.exerciseSimulationCurrentScore.totalExercises + 1;
    },
  },
  methods: {
    setExerciseData() {
      if (this.simulationType === 'exercises') {
        const simulationChild = this.$refs.simulation;
        if (simulationChild) {
          const exerciseData = simulationChild.getExerciseData();
          this.exerciseSimulationCurrentScore.totalExercises = exerciseData[0];
          this.totalSimulationExercises = exerciseData[0];
          this.totalSimulationExerciseScores = exerciseData[1];
          this.exerciseNames = exerciseData[2];
          this.exerciseInstructionContent = exerciseData[3];
        }
      }
    },

    antiSkidOnKeyUp(e) {
      if (e.keyCode === 44) {
        this.$copyText(
          'Taking Screenshots Is Not Allowed, Your Access Will Be Revoked If You Continue To Record, Screenshot or Share Simulations',
        );
        e.preventDefault();
      }
      setTimeout(() => {
        this.keyDown = false;
      }, 350);
    },
    antiSkidOnKeyDown(e) {
      this.keyDown = true;
      if (e.keyCode === 123) {
        this.$copyText(' ');
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 'E'.charCodeAt(0)) {
        e.preventDefault();
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === 'I'.charCodeAt(0)) {
        this.$copyText(' ');
        e.preventDefault();
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === 'J'.charCodeAt(0)) {
        e.preventDefault();
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode === 'C'.charCodeAt(0)) {
        this.$copyText(' ');
        e.preventDefault();
      }
      if (e.keyCode === '91'.charCodeAt(0) && e.keyCode === 'S'.charCodeAt(0)) {
        this.$copyText(' ');
        e.preventDefault();
      }
      if (e.keyCode === '93'.charCodeAt(0) && e.keyCode === 'S'.charCodeAt(0)) {
        this.$copyText(' ');
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 'S'.charCodeAt(0)) {
        this.$copyText(' ');
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0)) {
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 'H'.charCodeAt(0)) {
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 'A'.charCodeAt(0)) {
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 'F'.charCodeAt(0)) {
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 'E'.charCodeAt(0)) {
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 'C'.charCodeAt(0)) {
        this.$copyText(' ');
        e.preventDefault();
      }
      if (e.keyCode === 91) {
        this.simulationPaused = true;
        e.preventDefault();
      }
      if (e.keyCode === 92) {
        this.simulationPaused = true;
        e.preventDefault();
      }
      if (e.keyCode === 93) {
        this.simulationPaused = true;
        e.preventDefault();
      }
      if (e.ctrlKey && e.shiftKey) {
        this.simulationPaused = true;
        e.preventDefault();
      }
      if (e.shiftKey && e.keyCode === 18) {
        this.simulationPaused = true;
        e.preventDefault();
      }
      if (e.ctrlKey && e.keyCode === 18) {
        this.simulationPaused = true;
        e.preventDefault();
      }
      if (e.keyCode === 224 && e.shiftKey) {
        this.simulationPaused = true;
        e.preventDefault();
      }
      if (e.keyCode === 17) {
        e.preventDefault();
      }
      if (e.keyCode === 18) {
        e.preventDefault();
      }
    },
    antiLeaveWindow() {
      this.simulationPaused = true;
    },
    antiScreenshotDisableClick($event) {
      if ($event.ctrlKey || $event.shiftKey || $event.metaKey || $event.altKey) {
        return;
      }

      if (this.simulationPaused === true && this.keyDown === false) {
        this.simulationPaused = false;
      }
    },

    confirmResetSimulation() {
      this.renderKey++;
      this.leaveSimulation();
      this.playSimulation();
    },
    confirmExitSimulation() {
      this.$router.push('/simulations/select');
    },
    enableMarkButton() {
      this.$vs.loading.close('#markBtn> .con-vs-loading');
      this.disableMarkButton = false;
    },
    nextExerciseClick() {
      this.simulationExerciseSubmitPopup.active = false;
      this.simulationExerciseConfirmed++;

      const simulationChild = this.$refs.simulation;

      if (simulationChild) {
        simulationChild.nextExercise(); // Replace "childMethod" with the actual method name in your child component
      }
    },
    simQuizCompletePopupClose() {
      this.simulationQuizSubmitPopup.active = false;
    },
    simAlmostCompletePopupClose() {
      this.simulationCompleteNotQuizPopup.active = false;
    },
    simNotCompletePopupClose() {
      this.simulationNotCompletePopup.active = false;
    },
    simShowPopupClose() {
      this.simulationShowPopup.active = false;
    },
    exitSimulation() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Exit Simulation',
        text: 'Please confirm you want to exit the simulation. You will loose all of your progress.',
        accept: this.confirmExitSimulation,
        acceptText: 'Exit',
      });
    },
    submitCurrentAttempt(score = 0) {
      this.$http
        .post(`simulation/attempt/${this.simulationCurrentAttemptData.id}`, {
          score,
          timeTotal: this.getSeconds(this.stopwatch.timeTotal),
          timeActive: this.getSeconds(this.stopwatch.timeActive),
        })
        .then((response) => {
          this.simulationCurrentAttemptData = response.data.data.currentAttempt;
          // Add best attempt ROBERT
        })
        .catch(() => {});
    },
    getSeconds(stopwatch) {
      let seconds = 0;
      if (stopwatch.seconds) seconds = seconds + stopwatch.seconds;
      if (stopwatch.minutes) seconds = (seconds + stopwatch.minutes) * 60;
      if (stopwatch.hours) seconds = (seconds + stopwatch.hours) * 3600;
      if (stopwatch.days) seconds = (seconds + stopwatch.days) * 86400;
      return seconds;
    },
    getSimulationData(id) {
      this.$http
        .get(`simulation/${id}/start?courseId=${this.activeCourseInfo.id}`)
        .then((response) => {
          if (response.status === 200) {
            this.$vs.loading.close();
            this.simulationData = response.data.simulation;
            this.simulationCurrentAttemptData = response.data.currentAttempt;
            this.simulationBestAttemptData = response.data.bestAttempt;
            // Add best attempt ROBERT
            this.simulationType = this.simulationData.type;
            this.initSimulation();

            setTimeout(() => {
              this.setExerciseData();
            }, 300);
          }
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Error While Loading Simulation',
            text: 'Please ensure you have the courses selected for the simulation you are trying to access',
            color: 'danger',
            position: 'top-right',
          });
          this.$router.push('/simulations/select');
        });
    },
    initSimulation() {
      this.componentFile = this.simulationData.filePath;

      this.stopwatch.timeTotal = useStopwatch(true);
      this.stopwatch.timeActive = useStopwatch(true);
    },
    leaveSimulation() {
      this.simulationComplete = false;
      this.disableMarkButton = false;
      this.currentAttempt = 0;

      if (this.simulationType === 'exercises') {
        this.exerciseSimulationCurrentScore.currentExercise = 0;
        this.exerciseSimulationCurrentScore.percent = 0;
        this.simulationExercise = 1;
        this.simulationExerciseConfirmed = 1;
      }
    },
    skipExercise() {
      if (this.simulationExercise !== this.exerciseSimulationCurrentScore.totalExercises) {
        this.simulationExercise++;
        this.exerciseSimulationCurrentScore.currentExercise = this.simulationExercise;
        this.exerciseSimulationCurrentScore.percent = Math.round((this.simulationExercise / this.updatedTotalExercises) * 100);
        this.nextExerciseClick();
      } else {
        this.exerciseSimulationCurrentScore.currentExercise = this.simulationExercise + 1;
        this.exerciseSimulationCurrentScore.percent = Math.round(
          (this.exerciseSimulationCurrentScore.currentExercise / this.updatedTotalExercises) * 100,
        );
      }
    },
    markSimulation() {
      if (this.disableMarkButton === true) {
        return this.$vs.notify({
          color: 'danger',
          title: 'Submission Cooldown',
          text: 'Please wait before re-marking your simulation',
          position: 'top-right',
        });
      }
      //Disable BTN
      this.disableMarkButton = true;

      this.$vs.loading({
        background: '#187743',
        color: 'white',
        container: '#markBtn',
        scale: 0.45,
      });

      //Send to Sim Template to Mark
      this.$refs.simulation.markSimulation();
    },
    playSimulation() {
      this.getSimulationData(this.$route.params.simulationId);
    },
    resetSimulation() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Reset Simulation',
        text: 'Please confirm you want to reset the simulation. You will loose all of your progress.',
        accept: this.confirmResetSimulation,
        acceptText: 'Reset',
      });
    },
    simulationFullCorrect(score) {
      this.stopwatch.timeActive.pause();
      this.stopwatch.timeTotal.pause();

      this.submitCurrentAttempt(score);
      this.$confetti.start({});

      setTimeout(() => {
        this.$vs.loading.close('#markBtn> .con-vs-loading');

        if (this.simulationType === 'exercises') {
          if (this.exerciseSimulationCurrentScore.currentExercise === this.updatedTotalExercises) {
            this.simulationComplete = true;
          }
        } else {
          this.simulationComplete = true;
        }
      }, 1);

      if (this.simulationType === 'exercises') {
        if (this.simulationExercise !== this.exerciseSimulationCurrentScore.totalExercises) {
          this.simulationExercise++;
          this.exerciseSimulationCurrentScore.currentExercise = this.simulationExercise;
          this.exerciseSimulationCurrentScore.percent = Math.round((this.simulationExercise / this.updatedTotalExercises) * 100);
        } else {
          this.exerciseSimulationCurrentScore.currentExercise = this.simulationExercise + 1;
          this.exerciseSimulationCurrentScore.percent = Math.round(
            (this.exerciseSimulationCurrentScore.currentExercise / this.updatedTotalExercises) * 100,
          );
        }
        this.disableMarkButton = false;
      } else {
        this.simulationCurrentScore.percent = Math.round((score / this.simulationData.totalScore) * 100);
        this.simulationCurrentScore.correct = score;
      }

      setTimeout(() => {
        this.$confetti.stop();
      }, 3000);

      this.simulationFullCorrectSound.play();

      this.simulationSubmitPopup = {
        active: true,
        score,
        title: 'Amazing Job - Simulation Complete',
        type: 'complete',
      };
    },
    simulationExerciseCorrect(score) {
      this.stopwatch.timeActive.pause();
      this.stopwatch.timeTotal.pause();

      this.submitCurrentAttempt(score);
      this.$confetti.start({});

      setTimeout(() => {
        this.$vs.loading.close('#markBtn> .con-vs-loading');
      }, 1);

      this.updatedPopupTotalScoreAmount = this.totalSimulationExerciseScores[this.simulationExercise - 1];

      if (this.simulationExercise !== this.exerciseSimulationCurrentScore.totalExercises) {
        this.simulationExercise++;
        this.exerciseSimulationCurrentScore.currentExercise = this.simulationExercise;
        this.exerciseSimulationCurrentScore.percent = Math.round((this.simulationExercise / this.updatedTotalExercises) * 100);
      } else {
        this.exerciseSimulationCurrentScore.currentExercise = this.simulationExercise + 1;
        this.exerciseSimulationCurrentScore.percent = Math.round(
          (this.exerciseSimulationCurrentScore.currentExercise / this.updatedTotalExercises) * 100,
        );
      }
      this.disableMarkButton = false;

      setTimeout(() => {
        this.$confetti.stop();
      }, 1000);

      this.simulationFullCorrectSound.play();

      this.simulationExerciseSubmitPopup = {
        active: true,
        score,
        title: 'Amazing Job - Exercise Complete',
        type: 'complete',
      };
    },

    simulationGettingThere(score, dialog = true) {
      this.submitCurrentAttempt(score);

      setTimeout(() => {
        this.$vs.loading.close('#markBtn> .con-vs-loading');
        this.disableMarkButton = false;
      }, 5000);

      this.updatedPopupTotalScoreAmount = this.totalSimulationExerciseScores[this.simulationExercise - 1];

      this.simulationCurrentScore.percent = Math.round((score / this.simulationData.totalScore) * 100);
      this.simulationCurrentScore.correct = score;

      this.simulationGettingThereSound.play();
      this.currentAttempt++;

      if (dialog) {
        if (this.simulationType === 'exercises') {
          this.simulationExerciseSubmitPopup = {
            active: true,
            score,
            title: "You're Getting There - Try Again",
            type: 'warning',
          };
        } else {
          this.simulationSubmitPopup = {
            active: true,
            score,
            title: "You're Getting There - Try Again",
            type: 'warning',
          };
        }
      }
    },
    simulationUpdateScore(score) {
      // this.simulationFullCorrectSound.play();

      this.simulationCurrentScore.percent = Math.round((score / this.simulationData.totalScore) * 100);
      this.simulationCurrentScore.correct = score;

      if (this.simulationCurrentScore.correct === this.simulationData.totalScore) {
        this.simulationFullCorrect(score);
      }
    },
    simulationUpdateOptions(data) {
      this.simulationOptions = data;
    },
    simulationQuizCorrect(questions) {
      this.simulationFullCorrectSound.play();

      this.simulationCurrentScore.percent = Math.round((questions / this.simulationData.totalScore) * 100);
      this.simulationCurrentScore.correct = questions;

      if (this.simulationCurrentScore.correct === this.simulationData.totalScore) {
        this.simulationComplete = true;

        this.$confetti.start({});
        setTimeout(() => {
          this.$confetti.stop();
        }, 1000);
      }

      this.simulationQuizSubmitPopup = {
        active: true,
        questions,
        title: 'Amazing Job - Quiz Complete!',
        type: 'complete',
      };
    },
    simulationCompleteNotQuiz(score) {
      setTimeout(() => {
        this.$vs.loading.close('#markBtn> .con-vs-loading');
        this.disableMarkButton = false;
      }, 5000);

      this.updatedPopupTotalScoreAmount = this.totalSimulationExerciseScores[this.simulationExercise - 1];

      this.simulationCurrentScore.percent = Math.round((score / this.simulationData.totalScore) * 100);
      this.simulationCurrentScore.correct = score;

      this.simulationGettingThereSound.play();
      this.currentAttempt++;

      this.simulationCompleteNotQuizPopup = {
        active: true,
        title: 'Oops! Quiz Not Complete',
        type: 'complete',
      };
    },
    simulationNotComplete(score, message) {
      setTimeout(() => {
        this.$vs.loading.close('#markBtn> .con-vs-loading');
        this.disableMarkButton = false;
      }, 5000);

      this.updatedPopupTotalScoreAmount = this.totalSimulationExerciseScores[this.simulationExercise - 1];

      this.simulationCurrentScore.percent = Math.round((score / this.simulationData.totalScore) * 100);
      this.simulationCurrentScore.correct = score;

      this.simulationGettingThereSound.play();
      this.currentAttempt++;

      this.simulationNotCompletePopup = {
        active: true,
        title: 'Oops! Simulation Not Complete',
        message,
        type: 'warning',
      };
    },
    simulationShowPopupFunction(title, header, message) {
      this.simulationGettingThereSound.play();

      this.simulationShowPopup = {
        active: true,
        title,
        header,
        message,
        type: 'warning',
      };
    },
    toggleExplanationVideo() {
      if (this.showExplanationVideo === false) {
        this.showExplanationVideo = true;
      } else {
        this.$refs.videoPlayer.close();
      }
    },
    updateFullSimulation() {
      this.$vs.loading();
      this.updateSimulationProperties();

      this.$vs.notify({
        title: 'Updated Simulation Successfully',
        text: `Simulation ${this.simulationData.name} has been updated.`,
        color: 'success',
        position: 'top-right',
      });
    },
    updateSimulationProperties() {
      const pl = this.simulationData;
      pl.status = this.simulationData.status === undefined ? 'active' : this.simulationData.status;
      pl.difficulty = parseInt(this.simulationData.difficulty.id);

      if (pl.name === undefined) {
        return this.$vs.notify({
          title: 'Failed to update simulation',
          text: 'Please provide a Simulation Name',
          color: 'danger',
          position: 'top-right',
        });
      }
      this.$http
        .post(`simulations/${this.simulationData.id}`, pl)
        .then(() => {
          this.$vs.loading.close();
        })
        .catch((exception) => {
          this.$vs.loading.close();

          let error = 'An unknown error occurred while updating this simulation';
          if (exception.response) {
            error = exception.response.data.message;
          }

          return this.$vs.notify({
            title: 'Failed to update simulation',
            text: error,
            color: 'danger',
            position: 'top-right',
          });
        });
    },
    addEventListeners() {
      window.addEventListener('blur', this.antiLeaveWindow);
      window.addEventListener('keydown', this.antiSkidOnKeyDown);
      window.addEventListener('keyup', this.antiSkidOnKeyUp);
    },
    removeEventListeners() {
      window.removeEventListener('keydown', this.antiSkidOnKeyDown);
      window.removeEventListener('keyup', this.antiSkidOnKeyUp);
      window.removeEventListener('blur', this.antiLeaveWindow);
    },
  },
  onIdle() {
    if (!this.stopwatch.timeActive) return;
    this.stopwatch.timeActive.pause();
  },
  onActive() {
    if (!this.stopwatch.timeActive) return;
    this.stopwatch.timeActive.start();
  },
  mounted() {
    this.simulationTabError = new Audio('https://sims-cdn.intellectualpoint.com/sounds/effect/Notification.mp3');
    this.simulationFullCorrectSound = new Audio('https://sims-cdn.intellectualpoint.com/sounds/effect/Success.mp3');
    this.simulationGettingThereSound = new Audio('https://sims-cdn.intellectualpoint.com/sounds/effect/Error_Notification.mp3');

    window.simulationGettingThereSound = this.simulationGettingThereSound;
    window.simulationFullCorrectSound = this.simulationFullCorrectSound;
    window.simulationTabError = this.simulationTabError;

    this.wasSidebarOpen = this.$store.state.reduceButton;
    this.$store.commit('TOGGLE_REDUCE_BUTTON', true);

    this.removeEventListeners();
    this.addEventListeners();

    this.playSimulation();

    if (this.$acl.check('proctor')) {
      this.simulationAntiSkid = false;
      this.isUserProctor = true;
    }
  },
  beforeDestroy() {
    this.leaveSimulation();

    this.simulationSubmitPopup.active = false;
    this.simulationExerciseSubmitPopup.active = false;
    this.simulationQuizSubmitPopup.active = false;

    this.simulationData = null;
    this.componentFile = null;

    this.removeEventListeners();

    if (!this.wasSidebarOpen) this.$store.commit('TOGGLE_REDUCE_BUTTON', false);
  },
  watch: {
    enableEditMode() {
      this.getSimulationData(this.simulationData.id);
    },
  },
  components: {
    quillEditor,
    MovablePopup,
    RadialProgressBar,
    LottieAnimation,
  },
};
</script>

<style lang="scss">
//Add Dynamic Importing

@import 'css/Renderer';

.ql-container {
  font-family: 'Inter', Helvetica, Arial, sans-serif;
  font-size: inherit !important;
}

.ql-snow .ql-editor {
  h1 {
    font-size: 2em !important;
  }
  h2 {
    font-size: 1.5em !important;
  }
  h3 {
    font-size: 1.17em !important;
  }
  h5 {
    font-size: 0.83em !important;
  }
  h6 {
    font-size: 0.67em !important;
  }
}
</style>
